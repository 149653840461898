import axios from 'axios'
import { storageLocal } from '@/core/libs/functions'
import { _config } from '@/core/config/base'
export const http = () => {
	return new Promise(resolve => {
		storageLocal.getList([_config.api.token, 'palermo_unique_id']).then(response => {
			let headers = {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
			if (response[1]) {
				headers['X-Identifier'] = response[1]
			}
			if (response[0]) {
				headers['Authorization'] = response[0]
			}
			let instance = axios.create({
				baseURL: _config.api.url,
				headers: headers
			})
			resolve(instance)
		})
	})
}

export const httpHeaders = () => {
	return new Promise(resolve => {
		storageLocal.getList([_config.api.token, 'palermo_unique_id']).then(response => {
			let headers = {
				'Content-Type': 'application/json'
			}
			if (response[1]) {
				headers['X-Identifier'] = response[1]
			}
			if (response[0]) {
				headers['Authorization'] = response[0]
			}
			let data = {
				baseURL: _config.api.url,
				headers: headers
			}
			resolve(data)
		})
	})
}
