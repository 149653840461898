<script>
	import { filters } from '@/core/libs/filters'
	import cuponMixin from '@/core/mixins/cupon.mixin'
	export default {
		mixins: [cuponMixin],
		data: function() {
			return {
				products: null,
				shippingTotal: 0.0,
				totals: {
					total: 0.0,
					discount: 0.0,
					bulk: 0.0,
					shipping: 0.0
				}
			}
		},
		created: function() {
			this.setItems()
		},
		watch: {
			products: function() {
				if (this.products && this.products.length > 0) {
					this.getCupon()
				} else {
					this.totals = {
						total: 0.0,
						discount: 0.0,
						bulk: 0.0,
						qty: 0,
						shipping: 0.0
					}
					this.$store.commit('totals', this.totals)
				}
			},
			cartItems: {
				handler: function() {
					clearTimeout(this.timeOut)
					this.setItems()
				},
				deep: true
			},
			'$route.path': function() {
				this.setItems()
			},
			discount: function() {
				this.getTotals()
			},
			shippingTotal: function() {
				this.getTotals()
			}
		},
		methods: {
			getTotals: function() {
				let total = 0.0
				let discount = 0.0
				let bulk = 0.0
				let qty = 0
				let weight = 0.0
				if (this.products) {
					this.products.forEach(product => {
						let productDiscount = 0.0
						if (product.discount) {
							productDiscount = filters.discount(product.price, product.discount, product.discount_type_id)
						} else if (this.discount && this.currency) {
							let key = 'amount_' + this.currency.code.toLowerCase()
							productDiscount = filters.discount(product.price, this.discount[key], this.discount.type_id)
						}
						total += (parseFloat(product.price) - productDiscount) * parseInt(product.in_bag)
						bulk += parseFloat(product.price) * parseInt(product.in_bag)
						discount += productDiscount * parseInt(product.in_bag)
						qty += parseInt(product.in_bag)
						weight += parseFloat(product.weight) * parseInt(product.in_bag)
					})
					this.totals = {
						total: total + this.shippingTotal,
						discount: discount,
						bulk: bulk,
						qty: qty,
						shipping: this.shippingTotal,
						weight: weight
					}
					this.$store.commit('totals', this.totals)
				}
			},
			getProducts: function(items) {
				let httpRequest = {
					module: 'inventory',
					params: '?id=in:' + items
				}
				this.get(httpRequest)
					.then(products => {
						this.setProducts(products.data).then(response => {
							this.products = response
							this.getTotals()
							this.$store.commit('showCart', true)
							this.timeOut = setTimeout(() => {
								this.$store.commit('showCart', false)
							}, 3000)
						})
					})
					.catch(() => {
						this.products = null
					})
			},
			setProducts: function(data) {
				return new Promise(resolve => {
					let products = []
					this.cartItems.forEach(item => {
						data.forEach(prod => {
							if (prod.id == item.inventory_id) {
								prod.in_bag = item.quantity
								products.push(prod)
								return
							}
						})
					})
					resolve(products)
				})
			},
			setItems: function() {
				let items = ''
				let itemsArray = []
				if (this.cartItems && this.cartItems.length > 0) {
					this.cartItems.forEach(item => {
						if (itemsArray.indexOf(item.inventory_id) === -1) {
							itemsArray.push(item.inventory_id)
							items += item.inventory_id + ','
						}
					})
					items = items.substring(0, items.length - 1)
					this.getProducts(items)
				} else {
					this.products = null
				}
			}
		},
		computed: {
			currency: function() {
				return this.$store.state.currency
			},
			cartItems: function() {
				return this.$store.state.cart
			},
			discount: function() {
				return this.$store.state.discountCupon
			}
		}
	}
</script>
