<script>
	// import { _config } from '@/config/base'
	export default {
		data: function() {
			return {
				stripe: null,
				card: null,
				stripeTokenData: null,
				paymentData: null
			}
		},
		watch: {
			stripeConfig: function() {
				if (this.stripeConfig) {
					this.stripe = window.Stripe(this.stripeConfig.data.api.sandbox.public_key)
				}
			},
			'checkout.payment_method': function() {
				if (this.checkout.payment_method == 2) {
					this.stripeLoadElement()
				}
			}
		},
		methods: {
			stripeLoadElement: function() {
				// El Time out se utliza para permitir cargar el elemento del DOM antes de ejecutar la funcion
				setTimeout(() => {
					let elements = this.stripe.elements()
					this.card = elements.create('card', {
						style: {
							base: {
								padding: 10,
								textAlign: 'center',
								backgroundColor: '#FFF',
								fontSize: '14px',
								fontFamily: 'Georgia, serif',
								lineHeight: 2,
								fontWeight: 300
							}
						}
					})
					this.card.mount('#stripe-card')
				}, 300)
			},
			stripeCardPayment: function() {
				this.$store.commit('loading', true)
				this.stripe.createToken(this.card).then(response => {
					this.stripeTokenData = response.token
					this.paymentData = response.token
					this.$store.commit('loading', false)
				})
			},
			stripeOXXOConfirmation: function(paymentIntentSecret) {
				return new Promise((resolve, reject) => {
					this.$store.commit('loading', true)
					this.stripe
						.confirmOxxoPayment(paymentIntentSecret, {
							payment_method: {
								billing_details: {
									name: this.shippingAddress.first_name + ' ' + this.shippingAddress.last_name,
									email: this.shippingAddress.customer_email
								}
							}
						})
						.then(result => {
							if (result.error) {
								reject(result)
							} else {
								resolve(result)
							}
							this.$store.commit('loading', false)
						})
				})
			}
		},
		computed: {
			stripeConfig: function() {
				let config = null
				if (this.paymentProviders) {
					let result = this.paymentProviders.filter(provider => provider.slug === 'stripe')
					if (result.length > 0) {
						config = result[0]
						config.data = JSON.parse(config.public_data)
					}
				}
				return config
			}
		}
	}
</script>
