export const _config = {
	api: {
		url: 'https://api.abastecedorapalermo.com/api/v4/',
		token: 'palermo_jwt'
	},
	statics: {
		url: 'https://api.abastecedorapalermo.com/static/'
	},
	locale: {
		defaultLang: 'es'
	},
	cart: {
		name: 'palermo_cart',
		uniqueIdField: 'palermo_unique_id'
	},
	wishlist: {
		name: 'palermo_wishlist'
	},
	cupon: {
		name: 'palermo_cupon'
	},
	paypal: {
		currency: 'MXN',
		key: {
			sandbox: 'AQVoTcGNdixb5s8nHGxdjNYwIx8KGIAP6NiWoS6ETU2KoME-E4HNqBKUCg7NJyKKirxyZ_jrlrw7DBYF',
			production: ''
		},
		env: 'sandbox'
	}
}
