<template>
	<div class="home">
		<div class="h-banner">
			<img src="/static/img/troncos_arboles.jpg" alt="Estufas ecológicas" class="h-banner__image" />
			<div class="h-banner__content">
				<p class="h-banner__text">Comprometidos en mejorar la calidad de vida de los habitantes de la zonas rurales en México</p>
			</div>
		</div>
		<div class="h-quote">
			<p class="h-quote__text">LA MANO HUMANA AYUDANDO POR UN MUNDO VERDE</p>
			<img class="h-quote__logo" src="/static/img/logo-380x380.png" alt="Logo Abastecedora Palermo" />
		</div>
		<customers-component></customers-component>
		<div ref="productos" class="h-products">
			<div class="h-products__container">
				<h1 class="h-products__title">Productos</h1>
				<item-component v-for="(product, index) in products" :index="index" :key="index" :product="product"></item-component>
			</div>
		</div>
		<div class="otherproducts">
			<div class="otherproducts__container">
				<h1 class="otherproducts__title">Otros productos</h1>
				<div class="otherproducts__product">
					<div class="otherproducts__cover">
						<img class="otherproducts__image" src="/static/img/banos_01.jpg" alt="Baños eoclógicos" />
					</div>
					<h2 class="otherproducts__name">Baños Ecológicos</h2>
					<p class="otherproducts__text">
						Nuestro objetivo es que la gente marginada tenga un lugar donde pueda hacer sus necesidades fisiológicas, con comodidad, seguridad y de manera saludable sin exponerse a las inclemencias del tiempo, y a su vez que no se contamine el medio ambiente, ni los mantos acuíferos que rodean a los asentamientos humanos ya que la absorción por la tierra de los desechos sanitarios ocasionan
						contaminación al planeta.
					</p>
					<ul class="otherproducts__list">
						<li class="otherproducts__text otherproducts__text--list">iNo utiliza el agua que otros sistemas necesitan para funcionar, por lo tanto es ideal en zonas con problemas de abastecimiento de agua como las zonas áridas porque reduce el consumo de agua domiciliario.</li>
						<li class="otherproducts__text otherproducts__text--list">Evita la contaminación con heces fecales del aire que respiramos y de los mantos acuíferos próximos.</li>
						<li class="otherproducts__text otherproducts__text--list">Mejora las condiciones de higiene de los habitantes donde se aplica.</li>
						<li class="otherproducts__text otherproducts__text--list">Económicamente también representa una reducción en los pagos del servicio de agua.</li>
					</ul>
					<h3 class="otherproducts__subtitle">Procedimiento de uso</h3>
					<ul class="otherproducts__list">
						<li class="otherproducts__text otherproducts__text--list">Siempre después de utilizar el baño cubrir la excretas con tierra vegetal y polvo de cal, esto provocara que las excretas se sequen y se conviertan en materia orgánica.</li>
						<li class="otherproducts__text otherproducts__text--list">Y así al cabo de varios meses tendremos un depósito de materia orgánica que se podrá incorporar a la vegetación como un beneficio al medio ambiente.</li>
						<li class="otherproducts__text otherproducts__text--list">Una característica del Baño ecológico seco es la separación de la orina y el excremento, para facilitar el proceso de deshidratación del excremento.</li>
						<li class="otherproducts__text otherproducts__text--list">Esto se logra gracias a el segundo orificio con que cuenta la tasa ecológica seca que envía el orín a otro modulo diferente a la cámara de excremento.</li>
					</ul>
					<h3 class="otherproducts__subtitle">Ventajas</h3>
					<ul class="otherproducts__list">
						<li class="otherproducts__text othersproducts__text--list">Se adapta a cualquier entorno social.</li>
						<li class="otherproducts__text othersproducts__text--list">Sencillo de utilizar.</li>
						<li class="otherproducts__text othersproducts__text--list">Bajo Costo de su adquisición.</li>
						<li class="otherproducts__text othersproducts__text--list">Va acorde con las normas y políticas de organismos internacionales que tratan de reducir el grave y gran problema de la contaminación del planeta.</li>
					</ul>
					<a class="btn btn__red btn__small" href="/static/files/sanitario_fibra_vidrio.pdf" target="_blank">Ficha técnica y diagráma de armado</a>
				</div>
				<div class="otherproducts__product">
					<div class="otherproducts__cover">
						<img class="otherproducts__image" src="/static/img/calentadores_de_agua_solares.jpg" alt="Calentadores de agua solares" />
					</div>
					<h2 class="otherproducts__name">Calentadores de Agua Solares</h2>
					<p class="otherproducts__text"></p>
				</div>
			</div>
		</div>
		<a ref="nosotros"></a>
		<about-component></about-component>
		<partners-component></partners-component>
	</div>
</template>

<script>
	import aboutComponent from '@/components/about'
	import partnersComponent from '@/components/partners'
	import customersComponent from '@/components/customers'
	import httpService from '@/core/http_services/requests'
	import itemComponent from '@/components/item'
	export default {
		components: { aboutComponent, partnersComponent, customersComponent, itemComponent },
		mixins: [httpService],
		data: function() {
			return {
				hash: this.$route.hash,
				products: []
			}
		},
		created: function() {
			this.getProducts()
		},
		watch: {
			'$route.fullPath': function() {
				this.hash = this.$route.hash
				setTimeout(() => {
					this.goToAnchor()
				}, 100)
			}
		},
		mounted: function() {
			this.$nextTick(function() {
				this.goToAnchor()
			})
		},
		methods: {
			getProducts: function() {
				let httpRequest = {
					module: 'products',
					params: '?embed=inventory'
				}
				this.get(httpRequest)
					.then(products => {
						this.products = products.data
					})
					.catch(() => {
						this.products = null
					})
			},
			goToAnchor: function() {
				if (this.hash) {
					let refName = this.hash.replace('#', '')
					this.scrollToAnchorPoint(refName)
				}
			},
			scrollToAnchorPoint(refName) {
				let el = this.$refs[refName]
				el.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}
</script>

<style lang="sass">
	@import '../assets/sass/components/_form'
	@import '../assets/sass/components/_home'
</style>
