<template>
	<div class="product" v-if="product">
		<div class="product__container">
			<div class="product__images">
				<div class="product__cover">
					<img ref="productImage" :src="jsonImages(images[0], 'big', false)" :alt="product.title_es" class="product__image" />
				</div>
				<div class="product__thumbs">
					<img v-for="(image, index) in images" :key="index" @click.prevent="changeThumb" :src="jsonImages(image, 'big', false)" :alt="product.title_es" class="product__thumb" />
				</div>
			</div>
			<div class="product__info">
				<h1 class="product__title" v-text="product.title_es"></h1>
				<div class="atb">
					<h3 class="atb__title">Añadir al carrito</h3>
					<p class="atb__price">
						Precio: <span class="atb__price--strong">{{ product.price | price }}</span>
					</p>
					<form class="form" v-if="inventoryId">
						<div class="form__fieldset">
							<div class="form__container">
								<label for="quantity" class="form__label">Cantidad</label>
								<input type="text" class="form__input" name="quantity" id="quantity" v-model="cart.quantity" />
							</div>
							<div class="atb__button">
								<button class="btn btn__small btn__red" @click.prevent="addToCart(inventoryId)">AGREGAR</button>
							</div>
						</div>
					</form>
				</div>
				<article class="product__data" v-html="product.description_es"></article>
				<h2 class="product__subtitle">Beneficios</h2>
				<article class="product__data" v-html="product.details_es"></article>
			</div>
		</div>
		<div class="p-article">
			<div class="p-article__content">
				<div class="p-article__images">
					<img class="p-article__image p-article__image--wide" src="/static/img/mujer_lena.jpg" alt="Fogon" />
					<div>
						<img class="p-article__image p-article__image--short" src="/static/img/fogon.jpg" alt="Fogon" />
						<img class="p-article__image p-article__image--short" src="/static/img/mujeres_lena.jpg" alt="Fogon" />
					</div>
				</div>
				<div class="p-article__info">
					<h2 class="p-article__title">La quema de leña es fuente de dioxinas y otros contaminantes</h2>
					<p>Mas de 28 millones de mexicanos usan leña para cocinar sus alimentos</p>
					<p>La EPOC podría colocarse como la tercer causa de muerte en el país para 2020, han entretejido una tradición al momento de cocinar a fuego abierto.</p>
					<p>¿Sabía usted que alrededor de 3,000 millones de personas en el mundo queman leña en fogones abiertos en el interior de sus viviendas para cocinar o para calefacción?</p>
					<p>¿Sabía usted que al quemar leña se produce una gran variedad de contaminantes que son liberados al interior de la vivienda y que estos contaminantes pueden dañar su salud?</p>
					<p>Mientras que estar expuesto al humo de leña de manera ocasional puede causar sólo problemas menores y reversibles en la salud, estar expuesto de manera continua y diaria puede causar daños en la salud mucho más signi cativos.</p>
					<p>Quemar en un fogón abierto dentro de una vivienda resulta particularmente nocivo, ya que el humo que se queda “atrapado” en el interior del hogar puede alcanzar concentraciones peligrosas de contaminantes. La Organización Mundial de la Salud1 calcula que 2.5 millones de muertes en el mundo están relacionadas con la contaminación intramuros o en el interior de las viviendas.</p>
					<p>Quienes resultan más afectados por la contaminación intramuros son los niños, los bebés, las mujeres embarazadas, los adultos mayores, los fumadores y la gente con enfermedades como asma, bronquitis, en sema y neumonía. Además, el humo de leña inter ere con el desarrollo normal de los pulmones de bebés y niños pequeños</p>
				</div>
			</div>
		</div>
		<div class="gallery">
			<div class="gallery__container">
				<h1 class="gallery__title">Galería</h1>
				<div class="gallery__grid">
					<img v-for="(image, index) in galleryImages" @click.prevent="slider(index)" :key="index" :src="image.src" :alt="image.alt" class="gallery__thumb" />
				</div>
			</div>
		</div>
		<customers-component></customers-component>
		<about-component></about-component>
		<partners-component></partners-component>
		<div class="slider" v-if="active !== null">
			<button class="slider__close" @click.prevent="active = null">
				<svg enable-background="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
					<path fill="currentColor" d="m52.586 8.586-14.586 14.586v-5.172c0-1.104-.896-2-2-2s-2 .896-2 2v10c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2s-.896-2-2-2h-5.172l14.586-14.586c.781-.781.781-2.047 0-2.828s-2.047-.781-2.828 0z" />
					<path fill="currentColor" d="m10 56c.512 0 1.023-.195 1.414-.586l14.586-14.586v5.172c0 1.104.896 2 2 2s2-.896 2-2v-10c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2s.896 2 2 2h5.172l-14.586 14.586c-.781.781-.781 2.047 0 2.828.391.391.902.586 1.414.586z" />
				</svg>
			</button>
			<div ref="gallery" class="slider__container">
				<img v-for="(image, index) in galleryImages" :class="{ 'slider__image--active': index === active }" :key="index" :src="image.src" :alt="image.alt" class="slider__image" />
			</div>
			<button class="slider__arrow slider__arrow--left" @click.prevent="slider('prev')">
				<svg enable-background="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
					<path fill="currentColor" d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" />
				</svg>
			</button>
			<button class="slider__arrow slider__arrow--right" @click.prevent="slider('next')">
				<svg enable-background="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
					<path fill="currentColor" d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" />
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
	import aboutComponent from '@/components/about'
	import partnersComponent from '@/components/partners'
	import customersComponent from '@/components/customers'
	import httpService from '@/core/http_services/requests'
	import itemMixin from '@/core/mixins/items.mixin'
	import { jsonImages } from '@/core/libs/functions'

	const images = [
		{
			id: 1,
			src: '/static/img/tmp/galleries/estufas-01.jpg',
			alt: ''
		},
		{
			id: 2,
			src: '/static/img/tmp/galleries/estufas-02.jpg',
			alt: ''
		},
		{
			id: 3,
			src: '/static/img/tmp/galleries/estufas-03.jpg',
			alt: ''
		},
		{
			id: 4,
			src: '/static/img/tmp/galleries/estufas-04.jpg',
			alt: ''
		},
		{
			id: 6,
			src: '/static/img/tmp/galleries/estufas-06.jpg',
			alt: ''
		},
		{
			id: 7,
			src: '/static/img/tmp/galleries/estufas-07.jpg',
			alt: ''
		},
		{
			id: 8,
			src: '/static/img/tmp/galleries/estufas-08.jpg',
			alt: ''
		},
		{
			id: 9,
			src: '/static/img/tmp/galleries/estufas-09.jpg',
			alt: ''
		},
		{
			id: 10,
			src: '/static/img/tmp/galleries/estufas-10.jpg',
			alt: ''
		},
		{
			id: 11,
			src: '/static/img/tmp/galleries/estufas-11.jpg',
			alt: ''
		},
		{
			id: 12,
			src: '/static/img/tmp/galleries/estufas-12.jpg',
			alt: ''
		},
		{
			id: 13,
			src: '/static/img/tmp/galleries/estufas-13.jpg',
			alt: ''
		},
		{
			id: 14,
			src: '/static/img/tmp/galleries/estufas-14.jpg',
			alt: ''
		},
		{
			id: 15,
			src: '/static/img/tmp/galleries/estufas-15.jpg',
			alt: ''
		},
		{
			id: 16,
			src: '/static/img/tmp/galleries/estufas-16.jpg',
			alt: ''
		},
		{
			id: 17,
			src: '/static/img/tmp/galleries/estufas-17.jpg',
			alt: ''
		},
		{
			id: 18,
			src: '/static/img/tmp/galleries/estufas-18.jpg',
			alt: ''
		},
		{
			id: 19,
			src: '/static/img/tmp/galleries/estufas-19.jpg',
			alt: ''
		},
		{
			id: 20,
			src: '/static/img/tmp/galleries/estufas-20.jpg',
			alt: ''
		},
		{
			id: 21,
			src: '/static/img/tmp/galleries/estufas-21.jpg',
			alt: ''
		},
		{
			id: 22,
			src: '/static/img/tmp/galleries/estufas-22.jpg',
			alt: ''
		},
		{
			id: 23,
			src: '/static/img/tmp/galleries/estufas-23.jpg',
			alt: ''
		},
		{
			id: 24,
			src: '/static/img/tmp/galleries/estufas-24.jpg',
			alt: ''
		},
		{
			id: 25,
			src: '/static/img/tmp/galleries/estufas-25.jpg',
			alt: ''
		},
		{
			id: 26,
			src: '/static/img/tmp/galleries/estufas-26.jpg',
			alt: ''
		},
		{
			id: 27,
			src: '/static/img/tmp/galleries/estufas-27.jpg',
			alt: ''
		},
		{
			id: 28,
			src: '/static/img/tmp/galleries/estufas-28.jpg',
			alt: ''
		},
		{
			id: 29,
			src: '/static/img/tmp/galleries/estufas-29.jpg',
			alt: ''
		},
		{
			id: 30,
			src: '/static/img/tmp/galleries/estufas-30.jpg',
			alt: ''
		},
		{
			id: 31,
			src: '/static/img/tmp/galleries/estufas-31.jpg',
			alt: ''
		},
		{
			id: 32,
			src: '/static/img/tmp/galleries/estufas-32.jpg',
			alt: ''
		},
		{
			id: 33,
			src: '/static/img/tmp/galleries/estufas-33.jpg',
			alt: ''
		},
		{
			id: 34,
			src: '/static/img/tmp/galleries/estufas-34.jpg',
			alt: ''
		}
	]

	export default {
		components: { aboutComponent, partnersComponent, customersComponent },
		mixins: [httpService, itemMixin],
		data: function() {
			return {
				product: null,
				galleryImages: images,
				active: null,
				id: null,
				jsonImages: jsonImages,
				shippingAddress: [],
				billingAddress: null,
				shippingData: null
			}
		},
		created: function() {
			this.getProduct()
		},
		mounted: function() {
			document.addEventListener('keydown', e => {
				if (e.keyCode === 39) {
					this.slider('next')
				}
				if (e.keyCode === 37) {
					this.slider('prev')
				}
			})
		},
		methods: {
			changeThumb: function(e) {
				this.$refs.productImage.src = e.target.src
			},
			slider: function(slide = 0) {
				let start = 0
				let len = this.galleryImages.length
				switch (slide) {
					case 'prev':
						start = this.active !== null ? this.active - 1 : len - 1
						break
					case 'next':
						start = this.active !== null ? this.active + 1 : 0
						break
					default:
						start = slide
						break
				}
				if (start >= len) {
					start = 0
				} else if (start < 0) {
					start = len - 1
				}
				this.active = start
				document.addEventListener('keydown', e => {
					if (e.keyCode === 27) {
						this.active = null
					}
				})
			},
			getProduct: function() {
				this.id = this.$route.params.id
				let httpRequest = {
					module: 'products',
					params: '?embed=inventory'
				}
				this.getById(httpRequest, this.id)
					.then(product => {
						this.product = product.data
					})
					.catch(() => {
						this.product = null
					})
			}
		},
		computed: {
			inventoryId: function() {
				let id = null
				if (this.product.inventory !== undefined && this.product.inventory[0]) {
					id = this.product.inventory[0].id
				}
				return id
			}
		}
	}
</script>

<style lang="sass">
	@import "../assets/sass/components/_product"
</style>
