import { _config } from '@/core/config/base'
import { langRouting } from '@/core/config/lang.routing'
export const state = {
	lang: _config.locale.defaultLang,
	routes: langRouting[_config.locale.defaultLang],
	currencies: [],
	currency: {},
	cart: [],
	wishlist: [],
	showCart: false,
	showWishlist: false,
	totals: {
		total: 0.0,
		discount: 0.0,
		bulk: 0.0,
		qty: 0,
		shipping: 0.0,
		weight: 0.0
	},
	session: false,
	loginPopup: false,
	accountComponent: {
		name: 'access-data',
		id: null,
		type: null,
		addr: null
	},
	isMobile: true,
	notification: undefined,
	loading: false,
	discountCupon: null,
	sessionUniqueId: null
}
