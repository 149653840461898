export const filters = {
	price: (amount, { value = 1.0, code = 'MXN' } = {}, quantity = 1) => {
		// NOTE: SOLO SE UTILIZA EL LENGUAGE en-US DEBIDO A QUE es-MX NO MUESTRA EL SEPARADOR DE MILES
		let qty = parseFloat(amount) * quantity * parseFloat(value)
		let total = new Number(qty).toLocaleString('en-US', {
			// style: 'currency',
			//currency: code,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		})
		return '$' + total + ' ' + code
	},
	discount: (amount, value, type) => {
		let total = 0.0
		switch (type) {
			case 1:
				total = parseFloat(value)
				break
			case 2:
				total = parseFloat(amount) * (parseFloat(value) / 100)
				break
		}
		return total
	},
	priceWithDiscount: (amount, value, type) => {
		let total = 0.0
		switch (type) {
			case 1:
				total = parseFloat(amount) - parseFloat(value)
				break
			case 2:
				total = parseFloat(amount) - parseFloat(amount) * (parseFloat(value) / 100)
				break
			default:
				total = parseFloat(amount)
				break
		}
		return total
	},
	trans: (element, field, lang = 'es') => {
		return element[field + '_' + lang]
	},
	longDate: (date, lang = 'es') => {
		let langs = {
			es: 'es-MX',
			en: 'en-US'
		}
		let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
		return new Date(date).toLocaleDateString(langs[lang], options)
	}
}
