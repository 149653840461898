<template>
	<div class="cart">
		<div class="cart__container" v-show="cartItems.length > 0">
			<div class="cart__data">
				<h1 class="cart__title">Completa tu orden</h1>
				<section class="cart__section">
					<h2 class="cart__subtitle">Información de envío</h2>
					<ValidationObserver v-slot="{ handleSubmit }" v-if="!checkout.main_address">
						<form class="form" @submit.prevent="handleSubmit(useAddress)">
							<div class="form__fieldset">
								<ValidationProvider rules="required|max:45" v-slot="{ errors }">
									<div class="form__container">
										<label for="first_name" class="form__label form__label--required">Nombre(s)</label>
										<input type="text" class="form__input" name="first_name" id="first_name" v-model="shippingAddress.first_name" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|max:45" v-slot="{ errors }">
									<div class="form__container">
										<label for="last_name" class="form__label form__label--required">Apellido(s)</label>
										<input type="text" class="form__input" name="last_name" id="last_name" v-model="shippingAddress.last_name" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|email" v-slot="{ errors }">
									<div class="form__container">
										<label for="email" class="form__label form__label--required">Correo Electrónico</label>
										<input type="email" class="form__input" name="email" id="email" v-model="shippingAddress.customer_email" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|max:20" v-slot="{ errors }">
									<div class="form__container">
										<label for="phone" class="form__label form__label--required">Teléfono</label>
										<input type="text" class="form__input" name="phone" id="phone" v-model="shippingAddress.phone" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|max:255" v-slot="{ errors }">
									<div class="form__container">
										<label for="address" class="form__label form__label--required">Calle y número</label>
										<input type="text" class="form__input" name="address" id="address" v-model="shippingAddress.address" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|max:255" v-slot="{ errors }">
									<div class="form__container">
										<label for="suburb" class="form__label form__label--required">Colonia</label>
										<input type="text" class="form__input" name="suburb" id="suburb" v-model="shippingAddress.suburb" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|max:50" v-slot="{ errors }">
									<div class="form__container">
										<label for="city" class="form__label form__label--required">Ciudad</label>
										<input type="text" class="form__input" name="city" id="city" v-model="shippingAddress.city" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|max:50" v-slot="{ errors }">
									<div class="form__container">
										<label for="state" class="form__label form__label--required">Estado</label>
										<input type="text" class="form__input" name="state" id="state" v-model="shippingAddress.state" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider rules="required|max:5|numeric" v-slot="{ errors }">
									<div class="form__container">
										<label for="zip_code" class="form__label form__label--required">Código postal</label>
										<input type="text" class="form__input" name="zip_code" id="zip_code" v-model="shippingAddress.zip_code" />
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider v-slot="{ errors }">
									<div class="form__container">
										<label for="comments" class="form__label">Información adicional</label>
										<textarea rows="4" class="form__input" name="comments" id="comments" v-model="checkout.comments"></textarea>
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
								<ValidationProvider v-slot="{ errors }">
									<div class="form__container">
										<label for="billing_data" class="form__label">Datos de facturación (Opcional)</label>
										<textarea rows="4" class="form__input" name="billing_data" id="billing_data" v-model="billingAddress"></textarea>
										<span class="form__alert">{{ errors[0] }}</span>
									</div>
								</ValidationProvider>
							</div>
							<input class="btn btn__red btn__small" type="submit" value="Utilizar esta dirección" />
						</form>
					</ValidationObserver>
					<div v-if="checkout.main_address">
						<p class="cart__text"><strong class="cart__text--strong">Nombre:</strong> {{ shippingAddress.first_name }} {{ shippingAddress.last_name }}</p>
						<p class="cart__text"><strong class="cart__text--strong">Email:</strong> {{ shippingAddress.customer_email }}</p>
						<p class="cart__text"><strong class="cart__text--strong">teléfono:</strong> {{ shippingAddress.phone }}</p>
						<p class="cart__text"><strong class="cart__text--strong">Dirección:</strong> {{ shippingAddress.address }} {{ shippingAddress.suburb }} {{ shippingAddress.city }} {{ shippingAddress.state }} {{ shippingAddress.zip_code }}</p>
						<p class="cart__text"><strong class="cart__text--strong">Comentarios:</strong> {{ checkout.comments }}</p>
						<p class="cart__text"><strong class="cart__text--strong">Datos de Facturación:</strong> {{ billingAddress }}</p>
					</div>
				</section>
				<section class="cart__section">
					<h2 class="cart__subtitle">Métodos de pago</h2>
					<payment-options v-if="paymentProviders && checkout.main_address" :providers="paymentProviders"></payment-options>
				</section>
				<section class="cart__section" v-if="checkout.payment_method == 2">
					<h2 class="cart__subtitle">Tarjeta de Crédito</h2>
					<div v-if="!stripeTokenData">
						<div id="stripe-card"></div>
						<button class="btn btn__small btn__red" @click.prevent="stripeCardPayment">Utilizar esta tarjeta</button>
					</div>
					<div v-if="stripeTokenData">
						<p class="cart__text"><strong class="cart__text--strong">Últimos 4 dígitos de la tarjeta:</strong> {{ stripeTokenData.card.last4 }}</p>
						<p class="cart__text"><strong class="cart__text--strong">Emisor:</strong> {{ stripeTokenData.card.brand }}</p>
						<p class="cart__text"><strong class="cart__text--strong">Exipration Date:</strong> {{ stripeTokenData.card.exp_month }}/{{ stripeTokenData.card.exp_year }}</p>
						<p class="cart__text"><strong class="cart__text--strong">CVC:</strong> ***</p>
						<p class="cart__text"><strong class="cart__text--strong">Código postal:</strong> {{ stripeTokenData.card.address_zip }}</p>
					</div>
				</section>
			</div>
			<div class="cart__aside">
				<div class="cart__box">
					<h4 class="cart__heading">Totales</h4>
					<p class="cart__info">
						Subtotal: <strong class="cart__info--strong">{{ totals.total | price }}</strong>
					</p>
					<p class="cart__info">
						Descuento: <strong class="cart__info--strong">{{ totals.discount | price }}</strong>
					</p>
					<p class="cart__info">Envío: <strong class="cart__info--strong">En consolidado</strong></p>
					<p class="cart__info cart__info--total">Total: {{ totals.bulk | price }}</p>
				</div>
				<div class="cart__box">
					<h4 class="cart__heading">Carrito</h4>
					<cart-item v-for="(item, index) in products" :key="index" :item="item"></cart-item>
				</div>
				<!-- BOTONES DE PAGO -->
				<button v-show="stripeTokenData && (checkout.payment_method == 2 || checkout.payment_method == 3)" @click.prevent="saveOrder" class="btn btn__red btn__regular">Pagar ahora</button>
				<div v-show="checkout.payment_method == 1" class="payment__paypal" id="paypal-button-container"></div>
				<!--EOF BOTONES DE PAGO -->
			</div>
		</div>
		<partners-component></partners-component>
		<div class="notifications" v-if="showModal">
			<div class="notifications__container">
				<button class="btn btn__icon" @click.prevent="closeNotification">
					<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
						<path
							fill="currentColor"
							d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z"
						/>
					</svg>
				</button>
				<h4 class="notifications__title" v-text="notification.title"></h4>
				<p class="notifications__text" v-text="notification.text"></p>
				<svg class="notifications__icon notifications__icon--succcess" v-if="notification.success" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
					<circle style="fill:#25AE88;" cx="25" cy="25" r="25" />
					<polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="38,15 22,33 12,25" />
				</svg>
				<svg class="notifications__icon notifications__icon--failed" v-else viewBox="0 0 512 512.92258" xmlns="http://www.w3.org/2000/svg">
					<path
						d="m433.347656 512.921875h-352.898437c-27.71875-.003906-53.460938-14.355469-68.039063-37.9375-14.574218-23.578125-15.902344-53.023437-3.511718-77.820313l176.433593-352.914062c13.542969-27.117188 41.253907-44.25 71.566407-44.25s58.023437 17.132812 71.566406 44.25l176.433594 352.914062c12.390624 24.796876 11.0625 54.242188-3.511719 77.820313-14.574219 23.582031-40.320313 37.933594-68.039063 37.9375zm0 0"
						fill="#ff7761"
					/>
					<g fill="#fff">
						<path d="m256.898438 128.203125c8.835937 0 16 7.164063 16 16v192c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16v-192c0-8.835937 7.164062-16 16-16zm0 0" />
						<path d="m240.898438 384.203125h32v32h-32zm0 0" />
					</g>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
	import partnersComponent from '@/components/partners'
	import httpService from '@/core/http_services/requests'
	import totalsMixin from '@/core/mixins/totals.mixin'
	import cartItem from '@/components/cart.item'
	import paymentMixin from '@/core/mixins/payment.mixin'
	import validationMixin from '@/core/mixins/validation'
	import paymentOptions from '@/components/payment.options'
	import paypalPayment from '@/core/mixins/paypal.checkout'
	import stripePayment from '@/core/mixins/stripe.payment'

	export default {
		components: { partnersComponent, cartItem, paymentOptions },
		mixins: [httpService, totalsMixin, paymentMixin, validationMixin, paypalPayment, stripePayment],
		data: function() {
			return {
				showModal: false,
				shippingAddress: {
					first_name: null,
					last_name: null,
					address: null,
					address2: null,
					suburb: null,
					city: null,
					zip_code: null,
					country_id: 141,
					phone: null,
					customer_email: null,
					phone_country_code: 52
				}
			}
		},
		created: function() {
			this.getPaymentSettings()
			if (this.cartItems.length === 0) {
				this.$router.push({ name: 'homeView' })
			}
		},
		mounted: function() {
			this.$on('paymentOption', option => {
				this.checkout.payment_method = option
			})
			this.checkout.cart = this.cartItems
			document.addEventListener('keyup', e => {
				if (e.keyCode === 27) {
					this.closeNotification()
				}
			})
		},
		methods: {
			useAddress: function() {
				this.checkout.main_address = this.shippingAddress
			}
		}
	}
</script>

<style lang="sass">
	@import '../assets/sass/components/_cart'
</style>
