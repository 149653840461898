<template>
	<header class="header">
		<div class="header__container">
			<a href="/" class="logo">
				<img src="/static/img/logo-380x380.png" alt="Abastecedora Palermo Logo" class="logo__image" />
			</a>
			<button class="hamburguer" @click.prevent="toggleNav">
				<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 384.97 384.97" style="enable-background:new 0 0 384.97 384.97;" xml:space="preserve">
					<g id="Menu">
						<path fill="currentColor" d="M12.03,84.212h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03 C5.39,60.152,0,65.541,0,72.182C0,78.823,5.39,84.212,12.03,84.212z" />
						<path fill="currentColor" d="M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03 S379.58,180.455,372.939,180.455z" />
						<path fill="currentColor" d="M372.939,300.758H12.03c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h360.909 c6.641,0,12.03-5.39,12.03-12.03C384.97,306.147,379.58,300.758,372.939,300.758z" />
					</g>
				</svg>
			</button>
			<nav class="nav" :class="{ 'nav--visible': showNav }">
				<router-link class="nav__element nav__element--link" :to="{ name: 'homeView' }">Inicio</router-link>
				<router-link class="nav__element nav__element--link" :to="{ name: 'homeView', hash: 'nosotros' }">Nosotros</router-link>
				<router-link class="nav__element nav__element--link" :to="{ name: 'homeView', hash: 'productos' }">Productos</router-link>
				<router-link class="nav__element nav__element--link" :to="{ name: 'contactView' }">Contacto</router-link>
				<router-link class="nav__element nav__element--link" :to="{ name: 'cartView' }">
					<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.997 511.997" style="enable-background:new 0 0 511.997 511.997;" xml:space="preserve">
						<g>
							<path fill="currentColor" d="M405.387,362.612c-35.202,0-63.84,28.639-63.84,63.84s28.639,63.84,63.84,63.84s63.84-28.639,63.84-63.84 S440.588,362.612,405.387,362.612z M405.387,451.988c-14.083,0-25.536-11.453-25.536-25.536s11.453-25.536,25.536-25.536 c14.083,0,25.536,11.453,25.536,25.536S419.47,451.988,405.387,451.988z" />
							<path
								fill="currentColor"
								d="M507.927,115.875c-3.626-4.641-9.187-7.348-15.079-7.348H118.22l-17.237-72.12c-2.062-8.618-9.768-14.702-18.629-14.702 H19.152C8.574,21.704,0,30.278,0,40.856s8.574,19.152,19.152,19.152h48.085l62.244,260.443 c2.062,8.625,9.768,14.702,18.629,14.702h298.135c8.804,0,16.477-6.001,18.59-14.543l46.604-188.329 C512.849,126.562,511.553,120.516,507.927,115.875z M431.261,296.85H163.227l-35.853-150.019h341.003L431.261,296.85z"
							/>
							<path fill="currentColor" d="M173.646,362.612c-35.202,0-63.84,28.639-63.84,63.84s28.639,63.84,63.84,63.84s63.84-28.639,63.84-63.84 S208.847,362.612,173.646,362.612z M173.646,451.988c-14.083,0-25.536-11.453-25.536-25.536s11.453-25.536,25.536-25.536 s25.536,11.453,25.536,25.536S187.729,451.988,173.646,451.988z" />
						</g>
					</svg>
					<span class="nav__cart-qty">{{ myCart }} <span class="nav__cart-qty--responsive">Items</span></span>
				</router-link>
			</nav>
		</div>
	</header>
</template>

<script>
	import totalsMixin from '@/core/mixins/totals.mixin'
	export default {
		mixins: [totalsMixin],
		data: function() {
			return {
				showNav: false
			}
		},
		watch: {
			'$route.path': function() {
				this.showNav = false
			}
		},
		methods: {
			toggleNav: function() {
				this.showNav = !this.showNav
			}
		},
		computed: {
			myCart: function() {
				return this.$store.state.totals.qty
			}
		}
	}
</script>

<style lang="sass">
	@import "../assets/sass/components/_header"
</style>
