export const mutations = {
	lang(state, currentLang) {
		state.lang = currentLang
	},
	routes(state, routes) {
		state.routes = routes
	},
	currencies(state, currencies) {
		state.currencies = currencies
	},
	currency(state, currency) {
		state.currency = currency
	},
	cart(state, cart) {
		state.cart = cart
	},
	wishlist(state, item) {
		state.wishlist = item
	},
	showCart(state, show) {
		state.showCart = show
	},
	showWishlist(state, status) {
		state.showWishlist = status
	},
	totals(state, totals) {
		state.totals = totals
	},
	session(state, data) {
		state.session = data
	},
	loginPopup(state, data) {
		state.loginPopup = data
	},
	accountComponent(state, data) {
		state.accountComponent = data
	},
	notification(state, data) {
		state.notification = data
	},
	loading(state, data) {
		state.loading = data
	},
	discountCupon(state, data) {
		state.discountCupon = data
	},
	sessionUniqueId(state, id) {
		state.sessionUniqueId = id
	},
	isMobile(state, status) {
		state.isMobile = status
	}
}
