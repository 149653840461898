<template>
	<div class="f-elements">
		<a href="https://api.whatsapp.com/send?phone=528332452045" target="_blank" class="f-elements__icon" @click="gadsEvent">
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
				<path style="fill:#EDEDED;" d="M0,512l35.31-128C12.359,344.276,0,300.138,0,254.234C0,114.759,114.759,0,255.117,0 S512,114.759,512,254.234S395.476,512,255.117,512c-44.138,0-86.51-14.124-124.469-35.31L0,512z" />
				<path style="fill:#55CD6C;" d="M137.71,430.786l7.945,4.414c32.662,20.303,70.621,32.662,110.345,32.662 c115.641,0,211.862-96.221,211.862-213.628S371.641,44.138,255.117,44.138S44.138,137.71,44.138,254.234 c0,40.607,11.476,80.331,32.662,113.876l5.297,7.945l-20.303,74.152L137.71,430.786z" />
				<path
					style="fill:#FEFEFE;"
					d="M187.145,135.945l-16.772-0.883c-5.297,0-10.593,1.766-14.124,5.297 c-7.945,7.062-21.186,20.303-24.717,37.959c-6.179,26.483,3.531,58.262,26.483,90.041s67.09,82.979,144.772,105.048 c24.717,7.062,44.138,2.648,60.028-7.062c12.359-7.945,20.303-20.303,22.952-33.545l2.648-12.359 c0.883-3.531-0.883-7.945-4.414-9.71l-55.614-25.6c-3.531-1.766-7.945-0.883-10.593,2.648l-22.069,28.248 c-1.766,1.766-4.414,2.648-7.062,1.766c-15.007-5.297-65.324-26.483-92.69-79.448c-0.883-2.648-0.883-5.297,0.883-7.062 l21.186-23.834c1.766-2.648,2.648-6.179,1.766-8.828l-25.6-57.379C193.324,138.593,190.676,135.945,187.145,135.945"
				/>
			</svg>
		</a>
		<router-link :to="{ name: 'cartView' }" class="f-elements__icon" @click="gadsEvent">
			<svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
				<g id="Circle_Grid" data-name="Circle Grid"><circle cx="64" cy="64" fill="#f6c863" r="64" /></g>
				<g id="icon">
					<path d="m102.26 64.23-.21 1.2-.57 3.19-5.59 31.43a1.923 1.923 0 0 1 -1.9 1.59h-59.98a1.923 1.923 0 0 1 -1.9-1.59l-5.59-31.43-.57-3.19-.21-1.2z" fill="#ef5261" />
					<rect fill="#ef5261" height="11.345" rx="1.931" width="84" x="22" y="54.087" />
					<g fill="#dbd8dd">
						<rect height="23.333" rx="2.338" width="5.592" x="78.825" y="71.949" />
						<rect height="23.333" rx="2.338" width="5.592" x="61.204" y="71.949" />
						<rect height="23.333" rx="2.338" width="5.592" x="43.583" y="71.949" />
					</g>
					<rect fill="#575b6d" height="28.834" rx="2.758" transform="matrix(.82 .572 -.572 .82 43.293 -39.283)" width="6.597" x="80.857" y="34.831" />
					<rect fill="#575b6d" height="28.834" rx="2.758" transform="matrix(-.82 .572 -.572 -.82 107.978 64.566)" width="6.597" x="40.546" y="34.831" />
					<path d="m74.714 37.712-10.714 13.505-10.714-13.505h5.548v-17.351h10.332v17.351z" fill="#eeefee" />
					<path d="m102.05 65.43-.57 3.19h-74.96l-.57-3.19z" fill="#da364c" />
				</g>
			</svg>
		</router-link>
	</div>
</template>

<script>
	export default {
		methods: {
			gadsEvent: function() {
				window.gtag('event', 'conversion', { send_to: 'AW-942623044/jx5JCLy33vABEMSSvcED', event_callback: window.callback })
			}
		}
	}
</script>

<style lang="sass">
	@import "../assets/sass/components/_floating.elements.sass"
</style>
