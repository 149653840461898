<script>
	import { storageLocal } from '@/core/libs/functions'
	import { _config } from '@/core/config/base'
	import httpService from '@/core/http_services/requests'
	export default {
		// TODO: UNIR LOS CARRITOS DEL USUARIO CUANDO PASA DE NO REGISTRADO  A REGISTRADO
		mixins: [httpService],
		data: function() {
			return {
				showWlToolTip: false,
				currentColor: 0,
				item: {
					size_id: null,
					color_id: null,
					quantity: 1,
					item_id: null
				}
			}
		},
		methods: {
			addToCart: function(inventory_id, _product = null) {
				let result = []
				if (!_product) {
					result = this.product.inventory.filter(item => item.id === inventory_id)
				}
				if (result.length > 0 || _product) {
					let quantity = 1
					let product = null
					if (_product) {
						product = _product
						quantity = _product.quantity === null ? 0 : parseInt(_product.quantity)
					} else {
						product = result[0]
					}
					if (product.quantity && product.quantity > 0) {
						if (this.cart && this.cart.length > 0) {
							let list = this.cart
							let element = null
							list.forEach((item, index) => {
								if (item.inventory_id == product.id) {
									element = index
								}
							})
							if (element !== null) {
								list[element].quantity = parseInt(list[element].quantity) + quantity
							} else {
								list.push({
									item_id: this.product.id,
									color_id: product.color_id,
									size_id: product.size_id,
									inventory_id: product.id,
									quantity: quantity
								})
							}
							this.updateCartDatabase(list)
								.then(cart => {
									this.$store.commit('cart', cart.data.items)
									if (cart.data.items.length == 0) {
										localStorage.removeItem(_config.cart.name)
									} else {
										storageLocal.set(_config.cart.name, JSON.stringify(cart.data.items))
									}
								})
								.catch(() => {
									console.log('Shopping Cart not updated 1')
								})
						} else {
							let list = [
								{
									item_id: this.product.id,
									color_id: product.color_id,
									size_id: product.size_id,
									quantity: quantity,
									inventory_id: product.id
								}
							]
							this.updateCartDatabase(list)
								.then(cart => {
									this.$store.commit('cart', cart.data.items)
									if (cart.data.items.length === 0) {
										localStorage.removeItem(_config.cart.name)
									} else {
										storageLocal.set(_config.cart.name, JSON.stringify(cart.data.items))
									}
								})
								.catch(error => {
									console.log(error)
								})
						}
						if (this.isInWishlist(inventory_id)) {
							this.removeFromWishlist(inventory_id)
						}
					}
				}
			},
			updateCart: function(qty = 1) {
				let cart = this.cart
				console.log(cart)
				if (cart.length > 0) {
					cart.forEach((item, index) => {
						if (item.inventory_id == this.product.id) {
							cart[index].quantity = qty
						}
					})
				} else {
					cart.push({
						inventory_id: this.item.id,
						color_id: this.item.color_id,
						size_id: this.item.size_id,
						item_id: this.product.id,
						quantity: qty
					})
				}
				this.$store.commit('cart', cart)
				if (cart.length === 0) {
					localStorage.removeItem(_config.cart.name)
				} else {
					storageLocal.set(_config.cart.name, JSON.stringify(cart))
				}
			},
			removeFromCart: function(inventory_id) {
				let cart = this.cart
				cart.forEach((item, index) => {
					if (item.inventory_id == inventory_id) {
						cart.splice(index, 1)
						this.updateCartDatabase(cart)
							.then(cart => {
								if (cart.data.items !== undefined && cart.data.items && cart.data.items.length == 0) {
									this.$store.commit('cart', cart.data.items)
									localStorage.removeItem(_config.cart.name)
								} else {
									this.$store.commit('cart', [])
									storageLocal.set(_config.cart.name, JSON.stringify(cart.data.items))
								}
							})
							.catch(() => {
								console.log('Shopping Cart not updated 1')
							})
					}
				})
			},
			removeFromWishlist: function(inventory_id) {
				let cart = this.wishlist
				cart.forEach((item, index) => {
					if (item.inventory_id == inventory_id) {
						cart.splice(index, 1)
						this.$store.commit('wishlist', cart)
						if (cart.length === 0) {
							localStorage.removeItem(_config.wishlist.name)
						} else {
							storageLocal.set(_config.wishlist.name, JSON.stringify(cart))
						}
					}
				})
			},
			addToWishList: function(inventory_id, _product = false) {
				let result = []
				if (!_product) {
					result = this.product.inventory.filter(item => item.id === inventory_id)
				}
				if (result.length > 0 || _product) {
					let product = null
					if (_product) {
						product = _product
					} else {
						product = result[0]
					}
					if (this.wishlist.length > 0) {
						let list = this.wishlist
						let element = null
						list.forEach((item, index) => {
							if (item.id == product.id) {
								element = index
							}
						})
						if (element === null) {
							list.push({
								item_id: this.product.id,
								color_id: product.color_id,
								size_id: product.size_id,
								inventory_id: product.id,
								quantity: 1
							})
						} else {
							list.splice(element, 1)
						}
						this.$store.commit('wishlist', list)
						if (list.length === 0) {
							localStorage.removeItem(_config.wishlist.name)
						} else {
							storageLocal.set(_config.wishlist.name, JSON.stringify(list))
						}
					} else {
						let list = [
							{
								item_id: this.product.id,
								color_id: product.color_id,
								size_id: product.size_id,
								inventory_id: product.id,
								quantity: 1
							}
						]
						this.$store.commit('wishlist', list)
						if (list.length === 0) {
							localStorage.removeItem(_config.wishlist.name)
						} else {
							storageLocal.set(_config.wishlist.name, JSON.stringify(list))
						}
					}
					if (this.isInCart(inventory_id)) {
						this.removeFromCart(inventory_id)
					}
				}
			},
			isInWishlist: function(inventory_id) {
				let items = this.wishlist.filter(item => item.inventory_id == inventory_id)
				return items.length > 0
			},
			isInCart: function(inventory_id) {
				let items = this.cart.filter(item => item.inventory_id == inventory_id)
				return items.length > 0
			},
			validateItem: function() {
				if (this.item.id !== null && this.item.color_id !== null && this.item.size_id !== null && this.item.quantity > 0) {
					this.addToCart(null, this.item)
				} else {
					console.log('validation error')
				}
			},
			addProductToWihslist: function() {
				if (this.item.id) {
					this.addToWishList(this.item.id)
				} else {
					this.showWlToolTip = true
					setTimeout(() => {
						this.showWlToolTip = false
					}, 3000)
				}
			},
			updateCartDatabase: function(cart) {
				return new Promise((resolve, reject) => {
					let httpRequest = {
						module: 'shopping_cart',
						data: {
							cart: cart
						}
					}
					if (this.userUniqueId) {
						this.put(httpRequest, this.userUniqueId)
							.then(response => {
								resolve(response)
							})
							.catch(error => {
								reject(error)
							})
					} else {
						//
					}
				})
			},
			getCart: function(id = 1) {
				return new Promise((resolve, reject) => {
					let httpRequest = {
						params: '?embed=cart',
						module: 'shopping_cart'
					}
					this.getById(httpRequest, id)
						.then(cart => {
							if (cart.data.items) {
								this.$store.commit('cart', cart.data.items)
							}
							if (cart.meta.identifier_id) {
								resolve(cart.meta.identifier_id)
							} else {
								reject(cart.meta)
							}
						})
						.catch(error => {
							let response = JSON.parse(error.response)
							if (response.meta.identifier_id) {
								resolve(response.meta.identifier_id)
							} else {
								reject(error)
							}
						})
					/**
				storageLocal.get(_config.cart.name).then(cart => {
					if (cart) {
						let bag = JSON.parse(cart)
						this.$store.commit('cart', bag)
					}
				})
				*/
				})
			},
			getWishlist: function() {
				storageLocal.get(_config.wishlist.name).then(cart => {
					if (cart) {
						let bag = JSON.parse(cart)
						this.$store.commit('wishlist', bag)
					}
				})
			}
		},
		computed: {
			images: function() {
				if (this.colors.length > 0 && this.product && this.product.inventory && this.product.inventory[0].images) {
					let item = this.colors[this.currentColor].item
					let imgs = JSON.parse(this.product.inventory[item].images)
					return imgs
				} else {
					return [
						{
							id: null,
							sizes: {
								big: 'images/knox_dummy.jpg',
								medium: 'images/knox_dummy.jpg',
								small: 'images/knox_dummy.jpg',
								thumb: 'images/knox_dummy.jpg',
								pixel: 'images/knox_dummy.jpg'
							}
						}
					]
				}
			},
			colors: function() {
				let colors = []
				if (this.product) {
					this.product.inventory.forEach((item, index) => {
						let c = colors.filter(color => color.id == item.color_id)
						if (c.length === 0) {
							colors.push({
								id: item.color_id,
								color_es: item.color_es,
								color_en: item.color_en,
								icon: item.icon,
								inventory_id: item.id,
								item: index
							})
						}
					})
				}
				return colors
			},
			sizes: function() {
				let sizes = []
				if (this.colors !== undefined && this.colors && this.colors.length > 0) {
					let color_id = this.colors[this.currentColor].id
					this.product.inventory.forEach(item => {
						let s = sizes.filter(size => size.id == item.size_id)
						let i = this.$store.state.cart.filter(cartItem => cartItem.id == item.id)
						if (s.length === 0 && color_id === item.color_id) {
							let element = {
								id: item.size_id,
								size_es: item.size_es,
								size_en: item.size_en,
								code_es: item.code_es,
								code_en: item.code_en,
								inventory_id: item.id,
								available: true
							}
							if (i.length > 0 && i[0].quantity >= item.quantity) {
								element.available = false
							}
							sizes.push(element)
						}
					})
				}
				return sizes
			},
			currency: function() {
				return this.$store.state.currency
			},
			cart: function() {
				return this.$store.state.cart
			},
			wishlist: function() {
				return this.$store.state.wishlist
			},
			inWishlist: function() {
				let result = this.$store.state.wishlist.filter(item => item.item_id === this.product.id)
				return result.length > 0
			},
			userUniqueId: function() {
				return this.$store.state.sessionUniqueId
			}
		}
	}
</script>
