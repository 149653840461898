<script>
	import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
	import { required, email, min, confirmed, max, numeric, required_if } from 'vee-validate/dist/rules'
	extend('email', {
		...email,
		message: 'El valor de este campo no coincide con el de una direccion de correo electrónico'
	})
	extend('required_if', required_if)
	extend('required', {
		...required,
		message: 'Este campo es obligatorio'
	})
	extend('min', {
		...min,
		message: 'Este campo debe contener al menos {length} caracteres'
	})
	extend('max', {
		...max,
		message: 'Este campo no puede tener mas de {length} caracteres'
	})
	extend('numeric', {
		...numeric,
		message: 'El formato de este campo debe ser numérico'
	})
	extend('confirmed', {
		...confirmed,
		message: 'Debe de confirmar el valor en este campo, el valor debe coincidr'
	})
	extend('dateformat', {
		validate: value => {
			let dob = value.replace(/([^0-9/]+)/gm, '/')
			dob = dob.replace(/(^[^0-9]+)|([^0-9]+$)/g, '')
			let dateElements = dob.split('/')
			let unix = Date.parse(dateElements[2] + '-' + dateElements[1] + '-' + dateElements[0])
			if (!isNaN(unix)) {
				return true
			}
		},
		message: 'This is not a valid date'
	})
	export default {
		components: {
			ValidationProvider,
			ValidationObserver
		}
	}
</script>
