import Vue from 'vue'
import { filters } from '@/core/libs/filters'
import Vuex from 'vuex'
import i18n from '@/core/i18n'
import { state } from '@/core/vuex/state'
import { mutations } from '@/core/vuex/mutations'
import { langRouting } from '@/core/config/lang.routing'
import translateMixin from '@/core/mixins/translate.mixin'
import cartMixin from '@/core/mixins/items.mixin'
import httpService from '@/core/http_services/requests'
import LoginMixin from '@/core/mixins/login.mixin'
import { storageLocal } from '@/core/libs/functions'
import router from '@/core/router'
import App from './App.vue'

Vue.filter('price', filters.price)
Vue.filter('trans', filters.trans)
Vue.filter('discount', filters.priceWithDiscount)
Vue.filter('longDate', filters.longDate)

Vue.use(Vuex)
const store = new Vuex.Store({
	state,
	mutations
})

Vue.config.productionTip = false

Vue.mixin(translateMixin)

new Vue({
	store,
	i18n,
	router,
	mixins: [httpService, LoginMixin, cartMixin],
	created: function() {
		this.$i18n.locale = this.$route.meta.lang
		this.$store.state.lang = this.$route.meta.lang
		this.$store.commit('routes', langRouting[this.$route.meta.lang])
		this.getSession().then(() => {
			this.getCart()
				.then(identifier_id => {
					this.$store.commit('sessionUniqueId', identifier_id)
					storageLocal.set('palermo_unique_id', identifier_id)
					this.getWishlist()
					this.getCurrencies()
				})
				.catch(error => {
					console.log(error)
				})
		})
	},
	watch: {
		'$route.path': function() {
			this.$i18n.locale = this.$route.meta.lang
			this.$store.state.lang = this.$route.meta.lang
			this.$store.commit('routes', langRouting[this.$route.meta.lang])
		},
		'$store.state.session': function() {
			this.getCart()
				.then(() => {
					this.getWishlist()
				})
				.catch(error => {
					console.log(error)
				})
		}
	},
	methods: {
		getCurrencies: function() {
			let current = {}
			let httpRequest = {
				module: 'currencies'
			}
			this.get(httpRequest).then(currencies => {
				this.$store.commit('currencies', currencies.data)
				storageLocal.get('palermo_currency').then(currency => {
					if (currency !== 'undefined' && currency) {
						let c = JSON.parse(currency)
						let current = currencies.data.filter(curr => curr.id === c.id)
						if (current.length > 0) {
							this.$store.commit('currency', current[0])
						} else {
							current = currencies.data[0]
							storageLocal.set('palermo_currency', JSON.stringify(current))
							this.$store.commit('currency', currency)
						}
					} else {
						current = currencies.data[0]
						console.log(current)
						storageLocal.set('palermo_currency', JSON.stringify(current))
						this.$store.commit('currency', current)
					}
				})
			})
		}
	},
	computed: {
		indentifierId: function() {
			return this.$store.state.sessionUniqueId
		}
	},
	render: h => h(App)
}).$mount('#app')
