<template>
	<div class="cart__element">
		<img :src="thumb" :alt="item.title_es" class="cart__thumb" />
		<div class="cart__details">
			<h4 class="cart__name" v-text="item.title_es"></h4>
			<p class="cart__info">Cantidad: {{ item.in_bag }}</p>
			<p class="cart__info">Precio unidad: {{ item.price | price }}</p>
		</div>
	</div>
</template>

<script>
	import { _config } from '@/core/config/base'
	export default {
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		computed: {
			thumb: function() {
				let thumb = null
				let imgs = null
				if (this.item.images) {
					imgs = JSON.parse(this.item.images)
					thumb = imgs[0].sizes.thumb
				}
				return _config.statics.url + thumb
			}
		}
	}
</script>
