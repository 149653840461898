<template>
	<div class="contact">
		<div class="contact__map">
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4234.52291705568!2d-100.22626884387836!3d25.81079016065785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662ecfa88b323b1%3A0x9ee4e6726c58f854!2sSta.%20Rosa%20-%20Mezquital%208236%2C%20Valle%20del%20Salduero%2C%2066610%20Valle%20del%20Salduero%2C%20N.L.!5e0!3m2!1ses-419!2smx!4v1602606188573!5m2!1ses-419!2smx"
				frameborder="0"
				style="border:0;"
				allowfullscreen=""
				aria-hidden="false"
				tabindex="0"
			></iframe>
		</div>
		<div class="contact__content">
			<form class="form">
				<div class="form__fieldset">
					<div class="form__container">
						<label for="name" class="form__label form__label--required">Nombre Completo</label>
						<input type="text" class="form__input" name="name" id="name" />
					</div>

					<div class="form__container">
						<label for="email" class="form__label form__label--required">Correo Electrónico</label>
						<input type="email" class="form__input" name="email" id="email" />
					</div>

					<div class="form__container">
						<label for="phone" class="form__label form__label--required">Teléfono</label>
						<input type="text" class="form__input" name="phone" id="phone" />
					</div>

					<div class="form__container">
						<label for="subject" class="form__label form__label--required">Asunto</label>
						<input type="text" class="form__input" name="subject" id="subject" />
					</div>

					<div class="form__container">
						<label for="message" class="form__label form__label--required">Mensaje</label>
						<textarea class="form__input" name="message" id="message"></textarea>
					</div>
				</div>

				<input type="submit" value="Enviar mensaje" class="btn btn__black btn__regular" />
			</form>
			<div class="contact__data">
				<h1 class="contact__title">Contáctanos</h1>
				<h2 class="contact__heading">Dirección Oficina</h2>
				<p class="contact__text">Carretera Mezquital Santa Rosa # 8236 Int C Entre Del Lago y Carretera Ignacio Zaragoza a Santa Rosa C.P. 66610 Fraccionamiento Balcones de Santa Rosa Apodaca N.L.</p>
				<h2 class="contact__heading">Teléfonos</h2>
				<ul class="contact__list">
					<li class="contact__text">Cel: <a href="tel:00528332452045" class="contact__text--link">833-245-2045</a></li>
					<li class="contact__text">Tel: <a href="tel:00528183034197" class="contact__text--link">818-303-4197</a></li>
				</ul>
				<h2 class="contact__heading">Correo</h2>
				<ul class="contact__list">
					<li class="contact__text"><a href="mailto:j_tarno@hotmail.com" class="contact__text--link">j_tarno@hotmail.com</a></li>
					<li class="contact__text"><a href="mailto:ventas@abastecedorapalermo.com" class="contact__text--link">ventas@abastecedorapalermo.com</a></li>
				</ul>
			</div>
		</div>
		<customers-component></customers-component>
		<about-component></about-component>
		<partners-component></partners-component>
	</div>
</template>

<script>
	import aboutComponent from '@/components/about'
	import partnersComponent from '@/components/partners'
	import customersComponent from '@/components/customers'
	export default {
		components: { aboutComponent, partnersComponent, customersComponent }
	}
</script>

<style lang="sass">
	@import "../assets/sass/components/_contact"
</style>
