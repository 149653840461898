<script>
	import { _config } from '@/core/config/base'
	import { httpHeaders } from '@/core/http_services/headers'
	import LoadScript from 'vue-plugin-load-script'
	import Vue from 'vue'

	Vue.use(LoadScript)

	export default {
		data: function() {
			return {
				fetchHeaders: null
			}
		},
		created: function() {
			httpHeaders().then(response => {
				this.fetchHeaders = response
			})
		},
		mounted: function() {
			Vue.loadScript('https://www.paypal.com/sdk/js?client-id=AQVoTcGNdixb5s8nHGxdjNYwIx8KGIAP6NiWoS6ETU2KoME-E4HNqBKUCg7NJyKKirxyZ_jrlrw7DBYF&currency=MXN&disable-funding=card').then(() => {
				this.CreateObject()
			})
		},
		methods: {
			CreateObject: function() {
				// eslint-disable-next-line
				paypal
					.Buttons({
						style: {
							layout: 'vertical',
							color: 'black',
							shape: 'rect',
							label: 'pay'
						},
						// eslint-disable-next-line
						createOrder: (data, actions) => {
							this.$store.commit('loading', true)
							return fetch(_config.api.url + 'orders', {
								method: 'POST',
								headers: this.fetchHeaders.headers,
								body: this.orderData
							})
								.then(res => {
									if (res.status === 200) {
										return res.json()
									} else {
										//this.paymentNotification(res, true)
									}
								})
								.then(data => {
									// console.log(data)
									if (data !== undefined && data) {
										return data.data.result.id
									}
								})
						},
						// eslint-disable-next-line
						onApprove: (data, actions) => {
							return fetch(_config.api.url + 'orders/paypal-capture/' + data.orderID, {
								method: 'PUT',
								headers: this.fetchHeaders.headers
							}).then(res => {
								if (!res.ok) {
									this.paymentNotification(res, true)
								} else {
									this.paymentNotification(res)
								}
								this.$store.commit('loading', false)
							})
						},
						onCancel: () => {
							this.$store.commit('loading', false)
						},
						onError: error => {
							if (error.error === 'INSTRUMENT_DECLINED') {
								this.paymentNotification(error, true)
							} else {
								this.paymentNotification(error, true)
							}
							this.$store.commit('loading', false)
						}
					})
					.render('#paypal-button-container')
			}
		},
		computed: {
			paypalConfig: function() {
				let config = null
				if (this.paymentProviders) {
					let result = this.paymentProviders.filter(provider => provider.slug === 'paypal')
					if (result.length > 0) {
						config = result[0]
						config.data = JSON.parse(config.public_data)
					}
				}
				return config
			}
		}
	}
</script>
