<template>
	<div class="h-products__main hp-main">
		<div class="hp-main__cover">
			<img :src="jsonImages(images[0], 'medium', false)" :alt="product.title_es" class="hp-main__image" />
		</div>
		<div class="hp-main__content">
			<div class="hp-main__data">
				<h2 class="hp-main__title" v-text="product.title_es"></h2>
				<div class="hp-main__text" v-html="product.description_es"></div>
				<router-link :to="{ name: 'productView', params: { id: product.id } }" class="btn btn__regular btn__simple">Ver más información >></router-link>
			</div>
			<div class="atb">
				<h3 class="atb__title">Añadir al carrito</h3>
				<p class="atb__price">
					Precio: <span class="atb__price--strong">{{ product.price | price }}</span>
				</p>
				<form class="form" v-if="inventoryId">
					<div class="form__fieldset">
						<div class="form__container">
							<label for="quantity" class="form__label">Cantidad</label>
							<input type="text" class="form__input" name="quantity" id="quantity" v-model="item.quantity" />
						</div>
						<div class="atb__button">
							<button class="btn btn__small btn__red" @click.prevent="addToCart(inventoryId, item)">AGREGAR</button>
						</div>
					</div>
				</form>
				<p class="cart__info cart__info--nostock" v-if="nostock">Sin existencias</p>
			</div>
		</div>
	</div>
</template>

<script>
	import itemMixin from '@/core/mixins/items.mixin'
	import { jsonImages } from '@/core/libs/functions'
	export default {
		mixins: [itemMixin],
		props: {
			product: {
				type: Object,
				required: true
			},
			index: {
				type: Number,
				required: false
			}
		},
		created: function() {
			if (this.product.inventory) {
				this.item.size_id = this.product.inventory[0].size_id
				this.item.color_id = this.product.inventory[0].color_id
				this.item.inventory_id = this.product.id
				this.item.id = this.product.inventory[0].id
			}
		},
		data: function() {
			return {
				jsonImages: jsonImages
			}
		},
		computed: {
			nostock: function() {
				let stock = this.product.inventory[0].quantity
				return !stock || stock == 0
			},
			inventoryId: function() {
				let id = null
				if (this.product.inventory[0]) {
					id = this.product.inventory[0].id
				}
				return id
			}
		}
	}
</script>

<style lang="sass">
	@import '../assets/sass/components/_atb'
</style>
