export default {
	gallery_images: 'gallery-images',
	products: 'products',
	currencies: 'currencies',
	inventory: 'inventory',
	product_categories: 'product-categories',
	designers: 'designers',
	attributes: 'attributes',
	login: 'login',
	users: 'users',
	customers: 'customers',
	countries: 'countries',
	customer_addresses: 'customer-addresses',
	discount_cupons: 'discount-cupons',
	shopping_cart: 'shopping-cart',
	orders: 'orders',
	payment_providers: 'payment-providers',
	envia_ya: 'envia-ya',
	free_shipping: 'free-shipping'
}
