<template>
	<form class="form">
		<div class="form__fieldset">
			<template v-for="(provider, index) in providers">
				<div class="form__container" :key="index" v-if="provider.public_data">
					<label :for="provider.slug" class="form__label form__radio-container"><input type="radio" name="payment_method" :id="provider.slug" :value="index + 1" class="form__radio" v-model="option" /> {{ provider.name }}</label>
				</div>
			</template>
		</div>
	</form>
</template>

<script>
	export default {
		props: {
			providers: {
				type: Array,
				required: true
			}
		},
		data: function() {
			return {
				option: null
			}
		},
		watch: {
			option: function() {
				this.$parent.$emit('paymentOption', this.option)
			}
		}
	}
</script>
