<script>
	import ApiRoutes from '@/core/config/routes'
	import { http } from './headers'
	import { storageLocal } from '@/core/libs/functions'

	export default {
		methods: {
			get: function(request) {
				return new Promise((resolve, reject) => {
					if (request.module && ApiRoutes[request.module] !== undefined) {
						let params = request.params !== undefined && request.params ? request.params : ''
						let apiModule = ApiRoutes[request.module]
						http()
							.then(axios => {
								axios
									.get(apiModule + params)
									.then(response => {
										this.headersProcess(response.config.headers)
										resolve(JSON.parse(response.request.response))
									})
									.catch(error => {
										if (error.request.status) {
											reject(error.request)
										}
									})
							})
							.catch(error => console.log(error))
					} else {
						reject('Please provide an api module')
					}
				})
			},
			getById: function(request, id) {
				return new Promise((resolve, reject) => {
					if (request.module && ApiRoutes[request.module] !== undefined) {
						let params = request.params !== undefined && request.params ? request.params : ''
						let apiModule = ApiRoutes[request.module]
						http()
							.then(axios => {
								axios
									.get(apiModule + '/' + id + params)
									.then(response => {
										this.headersProcess(response.config.headers)
										resolve(JSON.parse(response.request.response))
									})
									.catch(error => {
										if (error.request && error.request.status) {
											reject(error.request)
										} else {
											console.log(error)
										}
									})
							})
							.catch(error => console.log(error))
					} else if (!id) {
						reject('Please provide a reference ID')
					} else {
						reject('Please provide an api module')
					}
				})
			},
			post: function(request) {
				this.$store.commit('loading', true)
				return new Promise((resolve, reject) => {
					if (request.module && ApiRoutes[request.module] !== undefined) {
						let apiModule = ApiRoutes[request.module]
						let params = ''
						if (request.params !== undefined) {
							params = request.params
						}
						http()
							.then(axios => {
								axios
									.post(apiModule + params, request.data)
									.then(response => {
										this.$store.commit('loading', false)
										resolve(JSON.parse(response.request.response))
									})
									.catch(error => {
										this.$store.commit('loading', false)
										if (error.request.status) {
											reject(error.request)
										}
									})
							})
							.catch(error => console.log(error))
					} else {
						reject('Please provide an api module')
					}
				})
			},
			put: function(request, id) {
				this.$store.commit('loading', true)
				return new Promise((resolve, reject) => {
					if (request.module && ApiRoutes[request.module] !== undefined) {
						let params = request.params !== undefined && request.params ? request.params : ''
						let apiModule = ApiRoutes[request.module]
						http()
							.then(axios => {
								axios
									.put(apiModule + '/' + id + params, request.data)
									.then(response => {
										this.$store.commit('loading', false)
										resolve(JSON.parse(response.request.response))
									})
									.catch(error => {
										this.$store.commit('loading', false)
										if (error.request.status) {
											reject(error.request)
										}
									})
							})
							.catch(error => console.log(error))
					} else {
						reject('Please provide an api module')
					}
				})
			},
			delete: function(request, id) {
				this.$store.commit('loading', true)
				return new Promise((resolve, reject) => {
					if (request.module && ApiRoutes[request.module] !== undefined) {
						let params = request.params !== undefined && request.params ? request.params : ''
						let apiModule = ApiRoutes[request.module]
						http()
							.then(axios => {
								axios
									.delete(apiModule + '/' + id + params)
									.then(response => {
										this.$store.commit('loading', false)
										resolve(JSON.parse(response.request.response))
									})
									.catch(error => {
										this.$store.commit('loading', false)
										if (error.request.status) {
											reject(error.request)
										}
									})
							})
							.catch(error => console.log(error))
					} else if (!id) {
						reject('Please provide a reference ID')
					} else {
						reject('Please provide an api module')
					}
				})
			},
			headersProcess(headers) {
				storageLocal
					.get('palermo_unique_id')
					.then(id => {
						if (id === undefined || !id || id !== headers['X-Identifier']) {
							storageLocal.set('palermo_unique_id', headers['X-Identifier'])
							this.$store.commit('sessionUniqueId', headers['X-Identifier'])
						} else {
							if (id !== this.customerIdentifier) {
								this.$store.commit('sessionUniqueId', id)
							}
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
		},
		computed: {
			customerIdentifier: function() {
				return this.$store.state.sessionUniqueId
			}
		}
	}
</script>
