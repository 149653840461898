<script>
	import httpService from '@/core/http_services/requests'
	import { storageLocal } from '@/core/libs/functions'
	import { _config } from '@/core/config/base'
	export default {
		mixins: [httpService],
		data: function() {
			return {
				cupon: null
			}
		},
		methods: {
			getCupon: function() {
				storageLocal.get(_config.cupon.name).then(cupon => {
					if (cupon !== undefined && cupon !== null) {
						let discount = JSON.parse(cupon)
						this.$store.commit('discountCupon', discount)
						this.cupon = discount.code
					}
				})
			},
			saveCupon: function(cupon) {
				storageLocal.set(_config.cupon.name, JSON.stringify(cupon))
				this.$store.commit('discountCupon', cupon)
			},
			cuponValidate: function() {
				if (this.cupon && this.cupon.length >= 5) {
					let httpRequest = {
						module: 'discount_cupons',
						params: '/validate?cupon=' + this.cupon
					}
					this.get(httpRequest)
						.then(response => {
							response.data.code = this.cupon
							this.saveCupon(response.data)
						})
						.catch(() => {
							this.$store.commit('discountCupon', null)
							this.cupon = null
						})
				}
			},
			removeCupon: function() {
				localStorage.removeItem(_config.cupon.name)
				this.$store.commit('discountCupon', null)
			}
		}
	}
</script>
