<template>
	<div id="app">
		<header-view></header-view>
		<router-view />
		<footer-view></footer-view>
		<div class="loading" v-if="loading">
			<img class="loading__icon" src="./assets/icons/loading.svg" alt="Procesando su solicitud" />
		</div>
		<floating-elements></floating-elements>
	</div>
</template>

<script>
	import headerView from '@/components/header'
	import footerView from '@/components/footer'
	import floatingElements from '@/components/floating.elements'

	export default {
		name: 'App',
		components: {
			headerView,
			footerView,
			floatingElements
		},
		computed: {
			loading: function() {
				return this.$store.state.loading
			}
		}
	}
</script>

<style lang="sass">
	@import "./assets/sass/base/_elements"
</style>
