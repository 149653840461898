export const langRouting = {
	es: {
		home: 'inicio',
		shoppingBag: 'carritoDeCompras',
		wishlistBag: 'misFavoritos',
		results: 'resultadosTienda',
		productView: 'vistaDeProducto',
		customerArea: 'areaDeClientes',
		checkoutLogin: 'accesoPago',
		payment: 'pago'
	},
	en: {
		home: 'home',
		shoppingBag: 'shoppingBag',
		wishlistBag: 'wishlistBag',
		results: 'shopResults',
		productView: 'productView',
		customerArea: 'customerArea',
		checkoutLogin: 'checkoutLogin',
		payment: 'payment'
	}
}
