import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag'
import HomeView from '@/views/home'
import ProductView from '@/views/product'
import ContactView from '@/views/contact'
import CartView from '@/views/cart'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: HomeView,
		name: 'homeView',
		meta: {}
	},
	{
		path: '/producto/:id',
		component: ProductView,
		name: 'productView',
		meta: {}
	},
	{
		path: '/contacto/',
		component: ContactView,
		name: 'contactView',
		meta: {}
	},
	{
		path: '/carrito-de-compra/',
		component: CartView,
		name: 'cartView',
		meta: {}
	}
]

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
})

Vue.use(
	VueGtag,
	{
		config: { id: 'GTM-WWPV6J9' }
	},
	router
)
export default router
