<script>
	const checkout = function() {
		return {
			payment_method: null,
			billing_address: {
				first_name: null,
				last_name: null,
				address: null,
				address2: null,
				suburb: null,
				city: null,
				zip_code: null,
				country_id: null,
				phone: null,
				customer_email: null,
				phone_country_code: null,
				comments: null
			},
			main_address: null,
			cupon: null,
			newsletter: false,
			cart: null
		}
	}
	export default {
		data: function() {
			return {
				paymentProviders: null,
				freeShipping: null,
				shippingProvider: null,
				billingAddress: null,
				checkout: checkout(),
				notification: {
					title: null,
					text: null,
					success: null
				}
			}
		},
		methods: {
			saveOrder: function() {
				let httpRequest = {
					module: 'orders',
					data: this.orderData
				}
				this.post(httpRequest)
					.then(response => {
						if (this.checkout.payment_method == 3) {
							this.stripeOXXOConfirmation(response.data.client_secret)
								.then(result => {
									this.paymentNotification(result)
								})
								.catch(error => {
									this.paymentNotification(error, true, { title: 'HA OCURRIDO UN ERROR', text: 'No se pudo generar el número de referencia de OXXO, por favor, comunicate con nosotros.' })
								})
						} else {
							this.paymentNotification(response)
						}
					})
					.catch(error => {
						this.paymentNotification(error, true, { title: 'HA OCURRIDO UN ERROR', text: 'No hemos podido completar tu solicitud, por favor cumunicate con nosotros para completar tu orden.' })
					})
			},
			paymentNotification: function(response, failed = false, message = null) {
				this.showModal = true
				console.log(response)
				if (failed) {
					this.notification = {
						title: message.title,
						text: message.text,
						success: false
					}
				} else {
					this.$store.commit('cart', [])
					this.$store.commit('totals', [])
					this.checkout = new checkout()
					this.notification = {
						title: 'ORDEN CREADA',
						text: 'Tu orden ha sido creada correctamente, te notificaremos por correo cuando sea enviada. Gracias por comprar en  Abastecedora Palermo',
						success: true
					}
				}
			},
			getPaymentSettings: function() {
				let httpRequest = {
					module: 'payment_providers'
				}
				this.get(httpRequest)
					.then(providers => {
						this.paymentProviders = providers.data
					})
					.catch(() => {
						this.paymentProviders = null
					})
			},
			closeNotification: function() {
				this.showModal = false
				if (this.notification.success === true) {
					this.$router.push({ name: 'homeView' })
				}
			}
		},
		computed: {
			session: function() {
				if (this.$store.state.session) {
					return this.$store.state.session
				} else {
					return null
				}
			},
			uniqueId: function() {
				return this.$store.state.sessionUniqueId
			},
			orderData: function() {
				let cupon = this.discount ? this.discount.coode : null
				return JSON.stringify({
					totals: this.totals,
					user_id: this.uniqueId,
					shipping_address: this.shippingAddress,
					billingAddress: this.billingAddress,
					cupon: cupon,
					comments: this.checkout.comments,
					payment_method: this.checkout.payment_method,
					payment_data: this.paymentData,
					shipping_data: this.shippingData,
					lang: this.lang,
					currency: this.currency.code,
					shipping_provider: this.shippingProvider,
					free_shipping: this.freeShipping
				})
			}
		}
	}
</script>
