import { _config } from '@/core/config/base'
export const onlyNumbers = function(e) {
	const k = e.keyCode
	if ((k >= 48 && k <= 57) || k === 8 || k === 37 || k === 39 || (k >= 96 && k <= 105)) {
		return false
	} else {
		e.preventDefault()
	}
}
export const storageLocal = {
	get: name => {
		return Promise.resolve().then(() => {
			const item = localStorage.getItem(name)
			if (item && item !== undefined) {
				return item
			} else {
				return null
			}
		})
	},
	getList: list => {
		return Promise.resolve().then(() => {
			let items = []
			list.forEach(element => {
				items.push(localStorage.getItem(element))
			})
			if (items.length > 0) {
				return items
			} else {
				return null
			}
		})
	},
	set: (element, json) => {
		return Promise.resolve().then(() => {
			return localStorage.setItem(element, json)
		})
	}
}
export const toDate = date => {
	const d = new Date(date.year, date.month, date.day)
	return d
}
export const sqlDate = (d, dateSeparator = '-', hourSeparator = ':') => {
	let month = d.getMonth() + 1
	month = month < 9 ? '0' + month : month
	let day = d.getDate()
	day = day < 9 ? '0' + day : day
	let hour = d.getHours()
	hour = hour < 9 ? '0' + hour : hour
	let minutes = d.getMinutes()
	minutes = minutes < 9 ? '0' + minutes : minutes
	let seconds = d.getSeconds()
	seconds = seconds < 9 ? '0' + seconds : seconds
	return d.getFullYear() + dateSeparator + month + dateSeparator + day + ' ' + hour + hourSeparator + minutes + hourSeparator + seconds
}
export const numberWithCommas = x => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const jsonImages = (image, size, json = true) => {
	let img = image
	if (json) {
		img = JSON.parse(image)
	}
	if (img) {
		return encodeURI(_config.statics.url + img.sizes[size])
	} else {
		return _config.statics.url + 'images/knox_dummy.jpg'
	}
}
export const dateFormat = (_date, zone = 'locale') => {
	let dob = _date.replace(/([^0-9/]+)/gm, '/')
	dob = dob.replace(/(^[^0-9]+)|([^0-9]+$)/g, '')
	let dateElements = dob.split('/')
	console.log(dob)
	let unix = Date.parse(dateElements[2] + '-' + dateElements[1] + '-' + dateElements[0])
	if (!isNaN(unix)) {
		let d = new Date(unix)
		let string = ''
		if (zone === 'locale') {
			string = d.getUTCDate() + '/' + (d.getUTCMonth() + 1) + '/' + d.getUTCFullYear()
		} else {
			string = d.getUTCFullYear() + '-' + (d.getUTCMonth() + 1) + '-' + d.getUTCDate()
		}
		return string
	} else {
		return null
	}
}
export const notReactive = data => {
	let d = {}
	for (const key in data) {
		d[key] = data[key]
	}
	return d
}
