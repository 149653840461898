<script>
	import httpService from '@/core/http_services/requests'
	import { storageLocal, notReactive, dateFormat } from '@/core/libs/functions'
	import VueJwtDecode from 'vue-jwt-decode'
	import { _config } from '@/core/config/base'

	export default {
		mixins: [httpService],
		data: function() {
			return {
				user: {
					email: null,
					password: null,
					newsletter: null,
					first_name: null,
					last_name: null,
					privacy_policy: null,
					dob: null
				},
				validation: {
					email: '',
					password: '',
					first_name: '',
					last_name: '',
					privacy_policy: '',
					newsletter: '',
					dob: ''
				}
			}
		},
		methods: {
			login: function() {
				let httpRequest = {
					module: 'login',
					data: this.user
				}
				this.post(httpRequest)
					.then(response => {
						storageLocal.set(_config.api.token, response.access_token).then(() => {
							this.$store.commit('loginPopup', false)
							this.setSession(response.access_token).then(() => {
								this.$router.push({ name: this.loginRedirectRoute })
							})
						})
					})
					.catch(error => {
						// TODO: MOSTRAR LOS ERRORES EN LOS FORMULARIOS CUANDO EL API DEVUELVA MENSAJES DE ERROR
						if (error.status == 400) {
							//
						} else if (error.status == 401) {
							this.formPassMessage = 'Correo y/o contraseña incorrecta, revise los datos proporcionados'
							this.formPassMessageStatus = 'form__message--error'
							setTimeout(() => {
								this.formPassMessage = null
								this.formPassMessageStatus = null
							}, 3000)
						} else {
							this.formPassMessage = 'Ha ocurrido un error al validar sus datos de acceso, si el problema persiste comunicate con nosotros'
							this.formPassMessageStatus = 'form__message--error'
							setTimeout(() => {
								this.formPassMessage = null
								this.formPassMessageStatus = null
							}, 3000)
						}
					})
			},
			registration: function() {
				let data = notReactive(this.user)
				data.dob = dateFormat(this.user.dob, 'global')
				let httpRequest = {
					module: 'users',
					data: data
				}
				this.post(httpRequest)
					.then(response => {
						storageLocal.set(_config.api.token, response.access_token).then(() => {
							this.$store.commit('loginPopup', false)
							this.setSession(response.access_token).then(() => {
								this.$router.push({ name: this.loginRedirectRoute })
							})
						})
					})
					.catch(error => {
						// TODO: MOSTRAR LOS ERRORES EN LOS FORMULARIOS CUANDO EL API DEVUELVA MENSAJES DE ERROR
						if (error.status == 400) {
							//
						} else {
							this.formPassMessage = 'Ha ocurrido un error al realizar su registro, si el problema persiste comunicate con nosotros'
							this.formPassMessageStatus = 'form__message--error'
							setTimeout(() => {
								this.formPassMessage = null
								this.formPassMessageStatus = null
							}, 3000)
						}
					})
			},
			setSession: function(token) {
				return new Promise(resolve => {
					let data = false
					let decoded = null
					try {
						decoded = VueJwtDecode.decode(token)
					} catch (error) {
						decoded = false
					}
					let ts = Math.round(new Date().getTime() / 1000)
					if (decoded && ts < decoded.exp) {
						data = {
							first_name: decoded.data.first_name,
							last_name: decoded.data.last_name,
							user_id: decoded.data.id,
							role_id: decoded.data.role_id,
							email: decoded.data.email,
							expiration: decoded.exp
						}
					}
					this.$store.commit('session', data)
					resolve(true)
				})
			},
			getSession: function() {
				return new Promise(resolve => {
					storageLocal.get(_config.api.token).then(token => {
						if (token !== undefined && token) {
							this.setSession(token)
							resolve(true)
						} else {
							resolve(true)
						}
					})
				})
			},
			logOut: function() {
				this.$store.state.session = false
				localStorage.removeItem(_config.api.token)
				if (this.$route.meta.login) {
					this.$router.push({ name: this.navs.home })
				}
			},
			getFBData: function(response) {
				if (response && response.status === 'connected') {
					let httpRequest = {
						params: '/facebook',
						module: 'login',
						data: response.authResponse
					}
					this.post(httpRequest)
						.then(response => {
							storageLocal.set(_config.api.token, response.access_token).then(() => {
								this.$store.commit('loginPopup', false)
								this.setSession(response.access_token).then(() => {
									this.$router.push({ name: this.navs.customerArea })
								})
							})
						})
						.catch(error => {
							if (error.status === 409) {
								this.formPassMessage = 'El correo ya fue registrado anteriormente, por favor, ingrese de la forma tradicional, puede solicitar una nueva contraseña si no recuerda la anterior.'
								this.formPassMessageStatus = 'form__message--error'
								setTimeout(() => {
									this.formPassMessage = null
									this.formPassMessageStatus = null
								}, 5000)
							} else {
								this.formPassMessage = 'Ha ocurrido un error al iniciar sesión con Facebook, si el problema persiste, comunicate con nosotros.'
								this.formPassMessageStatus = 'form__message--error'
								setTimeout(() => {
									this.formPassMessage = null
									this.formPassMessageStatus = null
								}, 5000)
							}
						})
				}
			}
		},
		computed: {
			navs: function() {
				return this.$store.state.routes
			}
		}
	}
</script>
